<template>
    <label class="mb-0 text-primary fw-bold" v-if="title_ != null">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
    <div :class="'input-group ' + classGroup_ + ' ' + ' ' + (error_ ? 'is-invalid': '')">
        <date-picker :modelValue="modelValue" 
            :class="class_" 
            :previewFormat="previewFormat_" 
            :format="format"
            :placeholder="placeholder_"
            @update:modelValue="$emit('update:modelValue', $event); onChange_($event)"
            :disabled="disabled_"
            range multi-calendars
            :max-date="maxDate_"
            :min-date="minDate_"
            :enable-time-picker="false" 
            locale="id"
            auto-apply
            :clearable="clearable_"
            :month-change-on-scroll="false"
            cancelText="Batal" selectText="Pilih" 
            >
            <!-- <template #clear-icon="{ clear }">
                
                <font-awesome-icon :icon="['fas', 'times']"  class="input-slot-image" @click="clear"/>
            </template> -->
        </date-picker>
        
    </div>
    <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
            <span v-for="(error,index) in error_" :key="index" class="d-block">
                {{ error }}
            </span>
    </small>
</template>

<script>
import moment from 'moment-timezone';
export default {
    props: {
        previewFormat_:             { type: String, default: 'dd/MM/yyyy' },
        format_:                    { type: String, default: 'dd/MM/yyyy' },
        classGroup_:                { type: String, default: 'input-group-outline' },   //input-group-dynamic
        class_:                     { type: String, default: '' },
        onChange_:                  {type: Function, default:()=>{}},
        title_:                     { type: String },
        error_:                     { type: Array },
        placeholder_:               { type: String },
        modelValue:                 { type: Array },
        disabled_:                  { type: Boolean, default: false },
        required_:                  { type: Boolean, default: false },
        clearable_:                 { type: Boolean, default: true },
        minDate_:                   { type:Date, default:null},
        maxDate_:                   { type:Date, default:null}
        
    },
    data: () => {
        return {
            date: ''
        }
    },
    emits: ['update:modelValue'],
    setup() {
        const format = (date) => {
            // if(date.constructor != Array){
            //     const day = date.getDate();
            //     const month = date.getMonth() + 1;
            //     const year = date.getFullYear();

            //     return `${day}/${month}/${year}`;
            // }else{
            //     const day1 = date[0].getDate();
            //     const month1 = date[0].getMonth() + 1;
            //     const year1 = date[0].getFullYear();

            //     const day2 = date[1].getDate();
            //     const month2 = date[1].getMonth() + 1;
            //     const year2 = date[1].getFullYear();

            //     return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`;
            // }
            if(date.constructor != Array){
                return moment(date).format('DD/MM/YYYY');
            }else{
                const date1 = moment(date[0]).format('DD/MM/YYYY');
                const date2 = moment(date[1]).format('DD/MM/YYYY');
                return `${date1} - ${date2}`;
            }
        }

        return {
            format
        }
    },
}
</script>

<style>
    .is-invalid{
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    }
    .dp__input{
        font-weight: 400 !important;
        line-height: 1.62 !important; 
    }
</style>